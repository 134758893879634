@import '../../style/default.less';
.login-page{
  .footer{
    //padding-top:40px;
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    height:60px;
    padding-top:20px;
    background:rgba(21,23,20, .5);
    font-size:12px;
    letter-spacing: 0.15em;
  }
  .ant-form-item{
    //margin-bottom:20px;
  }
  .ant-input-lg,
  .ant-btn-lg{
    height:40px;
    font-size:@fontD;
  }
  .login-form-button{
    width:100%;
    margin-top:15px;
  }
  margin-bottom:15px;
}
.login-header{
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:80px;
  line-height: 80px;
  background:rgba(21,20,13, .8);
  .logo{
    padding-left: 100px;
    font-size: 30px;
    color: white;
  }
  img{
    height:40px;
    margin-right: 15px;
  }
}
.login-content-wrap{
  min-height:550px;
  //height:calc(~'100vh - 100px');
  height:calc(~'100vh');
  background:url(./background.jpg) no-repeat center;
  background-size:cover;
}

.login-content{
  position:relative;
  top:50%;
  display:flex;
  max-width:1300px;
  margin:0 auto;
  padding:0 20px;
  transform:translateY(-50%);
  align-items:center;
  justify-content: center;

  .login-box-2{
    flex:9;
    line-height:1.4;
    color:#fff;
    // font-size:64px;
    // font-weight:bold;
	.title{
	  line-height:1;
	  margin-bottom:50px;
	  font-size:40px;
	  color:@colorC;
	  font-weight:bold;
	  text-align:center;
	}
	.error-msg-wrap{
	  position:absolute;
	  top:0;
	  left:0;
	  width:100%;
	
	  div{
	    visibility: hidden;
	    height:30px;
	    line-height:30px;
	    background:#f60c1a;
	    color:@colorM;
	    font-size:@fontD;
	    text-align:center;
	    transform:translateY(-30px);
	    transition: all .3s ease-out;
	
	    &.show{
	      visibility:visible;
	      transform:translateY(0);
	      transition: all .3s ease-out;
	    }
	  }
	}
	.left-form{
		width: 40%;
		height: 450px;
		// border: 1px solid red;
		height:450px;
		padding:40px 30px 0 30px;
		background:rgba(255,255,255, .6);
		overflow:hidden;
		
	}
  }
}
.login-box{
  position:relative;
  flex:3;
  height:450px;
  padding:40px 30px 0 30px;
  background:rgba(255,255,255, .8);
  overflow:hidden;
  .title{
    line-height:1;
    margin-bottom:50px;
    font-size:40px;
    color:@colorC;
    font-weight:bold;
    text-align:center;
  }
  .error-msg-wrap{
    position:absolute;
    top:0;
    left:0;
    width:100%;

    div{
      visibility: hidden;
      height:30px;
      line-height:30px;
      background:#f60c1a;
      color:@colorM;
      font-size:@fontD;
      text-align:center;
      transform:translateY(-30px);
      transition: all .3s ease-out;

      &.show{
        visibility:visible;
        transform:translateY(0);
        transition: all .3s ease-out;
      }
    }
  }
}
@media screen and (max-width: 1200px){
  .login-header{
    padding-left:30px;
    padding-right:30px;
  }
  .login-content{
    .word{
      font-size:48px;
    }
  }
}
@primary-color: #558FF2;