/** 常用色值 **/
/** 常用字体大小 **/
.header {
  position: fixed;
  width: 100%;
}
.header .header-top {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  text-align: right;
  background-image: url(../../static/media/u3.77cff12e.png);
}
.header .header-top a {
  margin-left: 40px;
}
.header .header-top .ant-carousel .slick-dots-bottom {
  display: none!important;
}
.header .header-top .bannerText {
  margin-top: 3px;
  float: left;
  margin-left: 5px;
  color: #FFFFFF;
  font-size: 23px;
}
.header .breadcrumb {
  height: 30px;
  background: #1A1A1A;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 30px;
  line-height: 30px;
  background: #353535 !important;
  color: #FFFFFF !important;
  margin-right: 0px;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 30px;
  line-height: 30px;
  background: #1A1A1A;
  color: #727272;
  margin-right: 0px;
  border-top: none;
  border-left: none;
  border-right: 1px solid #FFFFFF;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover {
  color: #FFFFFF;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 30px;
  margin: 0 10px;
}
.header .breadcrumb .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  color: #FFFFFF;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: -10px;
}
.header .breadcrumb .ant-tabs-bar {
  margin: 0;
}
.simple-page .header-top {
  background: #1890ff;
  color: #ffffff;
}
.ant-drawer-wrapper-body {
  background: #001529;
}
.ant-drawer-wrapper-body .ant-drawer-title {
  margin: 0;
  color: #fcfdfd;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-wrapper-body .ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #001529;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-drawer-wrapper-body .ant-drawer-body {
  padding: 24px;
  font-size: 14px;
  background: #001529;
  line-height: 1.5;
  word-wrap: break-word;
}

/** 常用色值 **/
/** 常用字体大小 **/
/** load **/
.ajax-loading {
  display: none;
}
.ajax-loading .loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 40px;
  height: 80px;
  line-height: 80px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  color: #fff;
}
.ajax-loading .loading img {
  width: 32px;
  vertical-align: middle;
}
.ajax-loading .loading span {
  margin-left: 12px;
}
.ajax-loading .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9998;
  background: #ffffff;
  opacity: 0.1;
}
ul,
li {
  list-style: none;
}
.clearfix::after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}
.content {
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 80px);
}
.mainContent {
  position: relative;
  padding: 15px 15px 0 15px;
  min-height: 800px;
}
.mainContent .ant-modal-wrap {
  position: absolute;
  overflow: visible;
}
.mainContent .ant-modal {
  top: 25px;
}
.mainContent .ant-modal-mask {
  top: 80px;
  right: 18px;
}
.content-wrap {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  margin-top: -3px;
}
.content-wrap .ant-table-wrapper {
  margin-left: -1px;
  margin-right: -2px;
}
.ant-card button {
  margin-right: 10px;
}
.button-box {
  position: relative;
  margin-top: -15px;
  float: right;
}
.button-box-left {
  position: relative;
  margin-top: -15px;
  float: left;
  width: 500px;
}
.msgIndexBox {
  background: #ddd;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 10px;
}
.msgIndexBox .msgIndex {
  width: 20%;
  height: 30px;
  line-height: 30px;
  float: left;
  cursor: pointer;
}
.msgIndexBox .showIndex {
  background: #558FF2;
  color: #fff;
}
.msgContent {
  height: 600px;
  overflow-y: auto;
}
.msgChange {
  height: 200px;
  overflow-y: auto;
}
.commonEnterpriseBox {
  border: 1px solid #ddd;
  padding: 0.35em 0.635em 0.75em;
  margin-bottom: 10px;
  border-radius: 10px;
}
.commonEnterpriseBox .commonEnterpriseBoxHead {
  line-height: 30px;
  padding: 0 10px;
  display: inline-block;
  color: #fff;
  height: 30px;
  margin-top: 5px;
  margin-bottom: 10px;
  background: #558FF2;
}
.commonEnterpriseBox table {
  width: 100%;
}
.commonEnterpriseBox table tr td {
  border: 1px solid #ddd;
  font-size: 12px;
  padding: 5px;
  text-align: center;
}
.commonEnterpriseBox table th {
  border: 1px solid #ddd;
  padding: 5px 5px;
  font-size: 12px;
  text-align: center;
  background-color: #EFEFEF;
}
.ant-drawer-content {
  background-color: #00152900;
}
.ant-drawer-content .ant-drawer-wrapper-body {
  background: #001529a1;
}
.ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-header {
  background: #00152900;
}
.ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
  background: #00152900;
}
.ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body .ant-menu-dark {
  background: #00152900;
}
.ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body .ant-menu-dark .ant-menu-sub {
  background: #00152900;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.45) inset;
}
.textButtonBox {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 21px;
}
.textButton {
  color: #558FF2;
  float: left;
  margin-left: 15px;
  cursor: pointer;
}
.stopTextButton {
  color: #FF3300;
  float: left;
  margin-left: 15px;
  cursor: pointer;
}
.textButton:hover {
  color: #895D52;
}
.hov:hover {
  background-color: yellow;
  width: 100px;
}
.stopTextButton:hover {
  color: #895D52;
}
.commonEnterpriseBox .checkDate .ant-input {
  width: 100px;
}
.commonEnterpriseBox .checkStartMinute .input {
  size: 70px;
}
.clickRowStyl {
  background: rgba(255, 51, 0, 0.7) !important;
}
.ant-table-tbody:hover > .clickRowStyl:hover > td {
  background: #ff3300;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  background-color: rgba(0, 0, 0, 0) !important;
}
.clickRowStylText {
  color: #FF3300;
}
.warningRowStyl {
  background: rgba(252, 206, 16, 0.7) !important;
}
.ant-table-tbody:hover > .warningRowStyl:hover > td {
  background: #fcce10;
}
.divposition {
  margin-top: 10px;
  margin-left: 14px;
}
.divposition1 {
  margin-top: 10px;
  margin-left: 5px;
}
.topContent .topBox {
  border: 1px solid #E6E9EC;
  border-radius: 15px;
  height: 85px;
  margin: 5px;
  width: 150px;
  float: left;
}
.statisticsBigBox {
  border: 1px solid #E6E9EC;
  border-radius: 5px;
  height: 151px;
  width: 22%;
  float: left;
  margin-left: 2%;
}
.statisticsLittleBox {
  border: 1px solid #E6E9EC;
  border-radius: 5px;
  height: 70px;
  width: 100%;
}
.statisticsLittleBox .forImg {
  height: 40px;
  margin-top: 14px;
  margin-left: 10px;
  float: left;
  border-right: 1px solid #E6E9EC;
}
.statisticsLittleBox .title {
  line-height: 70px;
  font-size: 16px;
  font-weight: bold;
  float: left;
  margin-left: 5px;
}
.statisticsLittleBox .number {
  line-height: 70px;
  font-size: 20px;
  color: #169BD5;
  float: left;
  margin-left: 5px;
}
.statisticsJumpBox {
  border: 1px solid #E6E9EC;
  border-radius: 5px;
  height: 151px;
  width: 6%;
  float: left;
  cursor: pointer;
  margin-left: 2%;
}
.statisticsTipsBox {
  border: 1px solid #E6E9EC;
  border-radius: 5px;
  height: 151px;
  width: 25%;
  float: left;
  margin-left: 2%;
}
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
Input:disabled {
  color: black;
  opacity: 1;
  -webkit-text-fill-color: black;
}
TextArea:disabled {
  color: black;
  opacity: 1;
  -webkit-text-fill-color: black;
}
.ant-select.ant-select-disabled .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
  color: black;
}

/** 常用色值 **/
/** 常用字体大小 **/
.footer {
  height: 100px;
  padding: 40px 0;
  text-align: center;
  color: #d7d7d7;
}

/** 常用色值 **/
/** 常用字体大小 **/
.login-page {
  margin-bottom: 15px;
}
.login-page .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding-top: 20px;
  background: rgba(21, 23, 20, 0.5);
  font-size: 12px;
  letter-spacing: 0.15em;
}
.login-page .ant-input-lg,
.login-page .ant-btn-lg {
  height: 40px;
  font-size: 16px;
}
.login-page .login-form-button {
  width: 100%;
  margin-top: 15px;
}
.login-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: rgba(21, 20, 13, 0.8);
}
.login-header .logo {
  padding-left: 100px;
  font-size: 30px;
  color: white;
}
.login-header img {
  height: 40px;
  margin-right: 15px;
}
.login-content-wrap {
  min-height: 550px;
  height: calc(100vh);
  background: url(../../static/media/background.aab49007.jpg) no-repeat center;
  background-size: cover;
}
.login-content {
  position: relative;
  top: 50%;
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
  transform: translateY(-50%);
  align-items: center;
  justify-content: center;
}
.login-content .login-box-2 {
  flex: 9 1;
  line-height: 1.4;
  color: #fff;
}
.login-content .login-box-2 .title {
  line-height: 1;
  margin-bottom: 50px;
  font-size: 40px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.login-content .login-box-2 .error-msg-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.login-content .login-box-2 .error-msg-wrap div {
  visibility: hidden;
  height: 30px;
  line-height: 30px;
  background: #f60c1a;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  transform: translateY(-30px);
  transition: all 0.3s ease-out;
}
.login-content .login-box-2 .error-msg-wrap div.show {
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}
.login-content .login-box-2 .left-form {
  width: 40%;
  height: 450px;
  padding: 40px 30px 0 30px;
  background: rgba(255, 255, 255, 0.6);
  overflow: hidden;
}
.login-box {
  position: relative;
  flex: 3 1;
  height: 450px;
  padding: 40px 30px 0 30px;
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;
}
.login-box .title {
  line-height: 1;
  margin-bottom: 50px;
  font-size: 40px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.login-box .error-msg-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.login-box .error-msg-wrap div {
  visibility: hidden;
  height: 30px;
  line-height: 30px;
  background: #f60c1a;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  transform: translateY(-30px);
  transition: all 0.3s ease-out;
}
.login-box .error-msg-wrap div.show {
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}
@media screen and (max-width: 1200px) {
  .login-header {
    padding-left: 30px;
    padding-right: 30px;
  }
  .login-content .word {
    font-size: 48px;
  }
}

